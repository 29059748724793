/* SEO Service for Updating Title, Meta Tags, Etc. */
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { ModeDevice } from "../constants/application/application-constants";
import { AppCommonService } from "./app-common.service";
import { AuthService } from "./auth.service";
declare var branch: any;
var dataLayer;
@Injectable({providedIn:'root'})
export class SeoService {
  private headElement: HTMLElement;
  private robots: HTMLElement;
  private metaTitle: HTMLElement;
  private metaDescription: HTMLElement;
  private metaKeywords: HTMLElement;
  private metaOGTitle: HTMLElement;
  private metaOGDescription: HTMLElement;
  private metaOGType: HTMLElement;
  private metaOGURL: HTMLElement;
  private metaOGImage: HTMLElement;
  private metaOGSitename: HTMLElement;
  private canonicalLink: HTMLElement;
  private googleSignInClientId: HTMLElement;
  private user_details: any;
  private cart_count: string;
  private user_id: string;
  isSSR: boolean = typeof window === "undefined";
  BASEURL: string; // base URL of server
  SECUREBASEURL: string; // Secure base URL of server
  environment: string = "prod"; // prod, dev, local
  prerenderStatusCodeElement: HTMLElement;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private titleService: Title,
    private commonService: AppCommonService,
    private authService: AuthService
  ) {
    this.setEnvironment();
    this.BASEURL = environment.baseServerUrl;
    this.SECUREBASEURL = environment.baseServerUrl;
    this.titleService = titleService;
    this.headElement = this.document.querySelector("head");
    this.robots = this.getOrCreateMetaElement("robots");
    this.metaTitle = this.getOrCreateMetaElement("title");
    this.metaDescription = this.getOrCreateMetaElement("description");
    this.metaKeywords = this.getOrCreateMetaElement("keywords");
    this.metaOGTitle = this.getOrCreateMetaElement("og:title", "og");
    this.metaOGDescription = this.getOrCreateMetaElement("og:description", "og");
    this.metaOGURL = this.getOrCreateMetaElement("og:url", "og");
    this.metaOGType = this.getOrCreateMetaElement("og:type", "og");
    this.metaOGImage = this.getOrCreateMetaElement("og:image", "og");
    this.metaOGSitename = this.getOrCreateMetaElement("og:site_name", "og");
    this.canonicalLink = this.getOrCreateLinkElement("canonical");
    this.googleSignInClientId = this.getOrCreateMetaElement("google-signin-client_id");
    this.prerenderStatusCodeElement = this.getOrCreateMetaElement("prerender-status-code");
  }
  /* Site Title */
  public getTitle(): string {
    return this.titleService.getTitle();
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  /* Meta Title */
  public getMetaTitle(): string {
    return this.metaTitle.getAttribute("content");
  }
  public setMetaTitle(newVal: string) {
    this.metaTitle.setAttribute("content", newVal);
  }
  /* Meta Description */
  public getMetaDescription(): string {
    return this.metaDescription.getAttribute("content");
  }
  public setMetaDescription(newVal: string) {
    this.metaDescription.setAttribute("content", newVal);
  }
  /* Meta Keywords */
  public getMetaKeywords(): string {
    return this.metaKeywords.getAttribute("content");
  }
  public setMetaKeywords(newVal: string) {
    this.metaKeywords.setAttribute("content", newVal);
  }
  /* Meta OG Title */
  public getMetaOGTitle(): string {
    return this.metaOGTitle.getAttribute("content");
  }
  public setMetaOGTitle(newVal: string) {
    this.metaOGTitle.setAttribute("content", newVal);
  }
  /* Meta OG Description */
  public getMetaOGDescription(): string {
    return this.metaOGDescription.getAttribute("content");
  }
  public setMetaOGDescription(newVal: string) {
    this.metaOGDescription.setAttribute("content", newVal);
  }
  /* Meta OG URL */
  public getMetaOGURL(): string {
    return this.metaOGURL.getAttribute("content");
  }
  public setMetaOGURL(newVal: string) {
    this.metaOGURL.setAttribute("content", newVal);
  }
  /* Meta OG Type */
  public getMetaOGType(): string {
    return this.metaOGType.getAttribute("content");
  }
  public setMetaOGType(newVal: string) {
    this.metaOGType.setAttribute("content", newVal);
  }
  /* Meta OG Image */
  public getMetaOGImage(): string {
    return this.metaOGImage.getAttribute("content");
  }
  public setMetaOGImage(newVal: string) {
    this.metaOGImage.setAttribute("content", newVal);
  }
  /* Meta OG Site Name */
  public getMetaOGSitename(): string {
    return this.metaOGSitename.getAttribute("content");
  }
  public setMetaOGSitename(newVal: string) {
    this.metaOGSitename.setAttribute("content", newVal);
  }
  /* Canonical Link */
  // public getCanonicalLink(): string { return this.canonicalLink.getAttribute('href'); }
  public setCanonicalLink(newVal: string) {
    this.canonicalLink.setAttribute("href", newVal);
  }
  /* Amp Link */
  // public getAmpLink(): string { return this.ampLink.getAttribute('href'); }
  // public setAmpLink(newVal: string) { this.ampLink.setAttribute('href', newVal); }
  /* Meta Robots */
  public getMetaRobots(): string {
    return this.robots.getAttribute("content");
  }
  public setMetaRobots(newVal: string) {
    this.robots.setAttribute("content", newVal);
  }
  public getMetaPrerender(): string {
    return this.prerenderStatusCodeElement.getAttribute("content");
  }
  public setMetaPrerender(newVal: string) {
    this.prerenderStatusCodeElement.setAttribute("content", newVal);
  }

  public getMetaGoogleSignInId(): string {
    return this.googleSignInClientId.getAttribute("content");
  }
  public setMetaGoogleSignInId(newVal: string) {
    this.googleSignInClientId.setAttribute("content", newVal);
  }
  /* All Meta Data at once */
  public setAllMetaData(obj: any) {
    if (obj["metatitle"] != null && (typeof obj["title"] === undefined || obj["title"] == null)) {
      this.setMetaTitle(obj["metatitle"]);
      this.setTitle(obj["metatitle"]);
    }
    if (obj["meta_description"] != null) {
      this.setMetaDescription(obj["meta_description"]);
    }
    if (obj["meta_keywords"] != null) {
      this.setMetaKeywords(obj["meta_keywords"]);
    }
    if (obj["og_title"] != null) {
      this.setMetaOGTitle(obj["og_title"]);
    }
    if (obj["og_description"] != null) {
      this.setMetaOGDescription(obj["og_description"]);
    }
    if (obj["og_url"] != null) {
      this.setMetaOGURL(obj["og_url"]);
    }
    if (obj["og_type"] != null) {
      this.setMetaOGType(obj["og_type"]);
    }
    if (obj["og_image"] != null) {
      this.setMetaOGImage(obj["og_image"]);
    }
    if (obj["og_sitename"] != null) {
      this.setMetaOGSitename(obj["og_sitename"]);
    }
    if (obj["canonical_url"] != null) {
      this.setCanonicalLink(obj["canonical_url"]);
    }
    if (environment.env != "prod") this.setMetaRobots("noindex");
    if (environment.env == "prod") this.setMetaGoogleSignInId("472948876950-f2lhqba5kr6q726g5mtn23jafbmsa5q2.apps.googleusercontent.com");
    else this.setMetaGoogleSignInId("388603709174-j8t6djjotrbbre8bh3sgf204cpsfcehr.apps.googleusercontent.com");
  }
  public getDefaultMetaData(): any {
    let meteData = {};
    meteData["title"] = "Buy Beauty Products. Book Salon & Spa Appointments. Online cosmetics Shopping. Purplle.com";
    meteData["metatitle"] = "Buy Beauty Products. Book Salon & Spa Appointments. Online cosmetics Shopping. Purplle.com";
    meteData["meta_description"] =
      "Buy Beauty Products. Book Salon & Spa Appointments. Online Beauty Shopping. Best discount deals. Free Shipping and Cash on Delivery.";
    meteData["meta_keywords"] = "Buy Beauty Products. Book Salon & Spa Appointments. Online cosmetics Shopping.";
    meteData["og_title"] = "Buy Beauty Products. Book Salon & Spa Appointments. Online cosmetics Shopping.";
    meteData["og_description"] =
      "Buy Beauty Products. Book Salon & Spa Appointments. Online Beauty Shopping. Best discount deals. Free Shipping and Cash on Delivery.";
    meteData["og_url"] = this.getBaseURL();
    meteData["canonical_url"] = this.getBaseURL();
    return meteData;
  }
  /**
   * get the HTML Element when it is in the markup, or create it.
   * @param name
   * @returns {HTMLElement}
   */
  setEnvironment() {
    this['ENVIRONMENT'] = environment.env;
    this.setBaseURL(this['ENVIRONMENT']);
  }
  getEnvironment() {
    return this['ENVIRONMENT'];
  }
  setBaseURL(env: any) {
    this.BASEURL = environment.baseServerUrl;
    this.SECUREBASEURL = environment.baseServerUrl;
  }
  getBaseURL(secure?: boolean) {
    if (secure != null && secure == true) {
      return this.SECUREBASEURL;
    } else {
      return this.BASEURL;
    }
  }

  private getOrCreateMetaElement(name: string, type?: any): HTMLElement {
    let el: HTMLElement;
    if (type == "og") {
      el = this.document.querySelector('meta[property="' + name + '"]');
    } else {
      el = this.document.querySelector("meta[name=" + name + "]");
    }
    if (typeof el === "undefined" || el === null) {
      el = this.document.createElement("meta");
      if (type == "og") {
        el.setAttribute("property", name);
      } else {
        el.setAttribute("name", name);
      }
      this.headElement.appendChild(el);
    }
    return el;
  }
  /**
   * get the HTML Element when it is in the markup, or create it.
   * @param name
   * @returns {HTMLElement}
   */
  private getOrCreateLinkElement(rel: string, type?: any): HTMLElement {
    let el: HTMLElement;
    el = this.document.querySelector("link[rel=" + rel + "]");
    if (typeof el === "undefined" || el === null) {
      el = this.document.createElement("link");
      el.setAttribute("rel", rel);
      this.headElement.appendChild(el);
    }
    return el;
  }
  initgtm(obj: any, eventType?: any, ecommObj?: any,purchaseBrandName?:string) {
    let common: any;
    let user: any;
    let page: any;
    if (obj.page_type && obj.page_type.toLowerCase().indexOf("listing_") > -1) {
      obj.page_type = obj.page_type.toLowerCase();
      page = {
        count_results: obj.count_results,
        page_module: obj.page_module,
        page_canonical_url: obj.page_url,
        page_amp_url: obj.page_amp_url,
        page_value: obj.page_type_value,
        page_title: obj.page_title,
        page_slug: obj.page_slug,
        page_number: obj.page_number,
        current_url: obj.current_url,
        listing_items: obj.listing_items,
      };
    }
    let data: any = {};
    if (eventType != "" && eventType != null && eventType != "story_ids_impressions") {
      let isLoggedIn = this.authService.isUserLoggedIn();
      if (isLoggedIn) {
        this.user_id = this.authService.getLoggedInUserID() || "";
        this.user_details = this.authService.getLoggedInUserDetails() || "";
      } else {
        this.user_id = "";
        this.user_details = "";
      }
      this.cart_count = this.commonService.getCartCount() || "";
      common = {
        section: obj.section || "",
        authType: obj.authType || "",
        page_type: obj.page_type || "",
        layout: this.commonService.isDesktop ? ModeDevice.desktop : ModeDevice.MobileEvent,
        page_canonical_url: obj.page_url || "",
        page_amp_url: obj.page_amp_url || "",
        page_value: obj.page_type_value || "",
        iconSelectName: obj.iconSelectName ? obj.iconSelectName : "default",
        iconSelectValue: obj.iconSelectValue ? obj.iconSelectValue : "default",
        page_title: obj.page_title || "",
        cart_count: this.cart_count || "",
      };
      user = {
        userid: this.user_id || "",
        email: this.user_details["user"] != null ? this.user_details["user"]["email"] : "",
        username: this.user_details["user"] != null ? this.user_details["user"]["username"] : "",
        gender: this.user_details["user"] != null ? this.user_details["user"]["sex"] : "",
        is_first_time_buyer: this.user_details["user"] != null ? this.user_details["user"]["is_first_time_buyer"] : "false",
        isFtPurchase: obj.is_ft,
      };
      switch (obj.page_type) {
        case "PRODUCT_DETAIL":
          page = {
            product_id: obj?.product_id || "",
            product_name: obj?.product_name || "",
            stock_status: obj?.stock_status || 0,
            mrp: obj?.mrp || 0,
            our_price: obj?.our_price,
            offer_price: obj?.offer_price,
            avg_rating: obj?.avg_rating,
            count_rating: obj?.count_rating,
            count_reviews: obj?.count_reviews,
            count_images: obj?.count_images,
            product_seller_id: obj?.product_seller_id,
            category_name: obj?.category_name,
            items: obj?.items
          };
          break;
        case "LISTING_SHOP":
          page = {
            count_results: obj.count_results,
            page_module: obj.page_module,
            page_canonical_url: obj.page_url,
            page_amp_url: obj.page_amp_url,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
            page_slug: obj.page_slug,
            page_number: obj.page_number,
            current_url: obj.current_url,
            listing_items: obj.listing_items,
          };
          break;
        case "story-detail":
          page = {
            story_id: obj.story_id,
            page_value: obj.page_type_value,
          };
          break;
        case "checkout":
          page = {
            items: obj.items,
            cart_id: obj.cart_id,
            product_sellet_items: obj.product_sellet_items,
            order_total: obj.transaction_total,
            order_sub_total: obj.transaction_sub_total,
          };
          break;
        case "review-popup":
          page = {
            product_id: obj.product_id,
            category_id: obj.category_id,
          };
          break;
        case "THREAD_DETAIL":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_module: obj.page_module,
            page_title: obj.page_title,
            feature_type: obj.feature_type,
          };
          break;
        case "Offers":
          page = {
            page_value: obj.page_type_value,
            page_type: obj.page_type,
            page_title: obj.page_title,
          };
          break;
        case "cart":
          page = {
            cart_id: obj.cart_id,
            page_type: obj.page_type,
            cart_items: obj.cart_items,
          };
          break;
        case "STORY_DETAIL":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
            page_module: obj.page_module,
            feature_type: obj.feature_type,
            story_id: obj.story_id,
          };
          break;
        case "FINDMYFIT":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
            page_module: obj.page_module,
            feature_type: obj.feature_type,
          };
          break;
        case "LISTING_THREAD":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
            page_module: obj.page_module,
            feature_type: obj.feature_type,
            count_results: obj.count_results,
            page_number: obj.page_number,
          };
          break;
        case "LISTING_HASHTAG":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
            page_module: obj.page_module,
            feature_type: obj.feature_type,
            count_results: obj.count_results,
            page_number: obj.page_number,
          };
          break;
        case "OFFERS":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
          };
          break;
        case "static-page":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
          };
          break;
        case "CHAT_ONBOARD":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
          };
          break;
        case "CHAT_LANDING":
          page = {
            page_type: obj.page_type,
            page_value: obj.page_type_value,
            page_title: obj.page_title,
          };
          break;
        case "order-summary":
          page = {
            page_type: obj?.page_type,
            page_value: obj?.page_type_value,
            page_title: obj.page_title,
            order_id: obj?.order_id,
            order_total: obj?.order_total,
            payment_mode: obj?.payment_mode,
            item_ids: obj?.item_ids,
            items: obj?.content_items,
            quantity: obj?.quantity,
            revenue: obj?.revenue,
            coupon_code: obj?.coupon_code,
            branch_content_items: obj?.content_items?.length ? obj?.content_items?.map(item => this.getBranchContentItem(item)) : [],
          };
          break;
        case "payment_methods":
          page = {
            page_type: obj?.page_type,
            page_value: obj?.page_type_value,
            page_title: obj?.page_title,
            item_ids: obj?.item_ids,
            items: obj?.items,
            order_total: obj?.transaction_sub_total,
          };
          break;
      }
      if (obj.page_type == "order-summary") {
        //Marco PURCHASE event
        let productList = [];
        if (obj.content_items && obj.content_items.length > 0) {
          for (let i = 0; i < obj.content_items.length; i++) {
            let item = {};
            item["id"] = obj?.content_items[i]?.product_id;
            item["name"] = obj?.content_items[i]?.product_name;
            item["seller"] = "";
            item["quantity"] = parseInt(obj?.content_items[i]?.quantity);
            item["value"] = parseFloat(obj?.content_items[i]?.our_price);
            item["currency"] = "INR";
            productList.push(item);
          }
        }
        let marcoParams = {
          event: "marcoTrigger",
          marcoParameters: {
            eventName: "purchase",
            productList: productList,
            orderId: obj.order_id,
            npa: false,
          },
        };
        dataLayer = window["dataLayer"] || [];
        dataLayer.push(marcoParams);
      }
      data["common"] = common;
      data["user"] = user;
      data["page"] = page;
      if (typeof branch !== "undefined") {
      }
    } else if (eventType != "" && eventType != null && eventType == "story_ids_impressions") {
      data["story_impressions_ids"] = obj.story_ids;
    }
    if (eventType != "" && eventType != null) {
      data["event"] = eventType;
    }
    if (ecommObj && ecommObj !== undefined && ecommObj != null) {
      data["ecommerce"] = ecommObj;
    }
    if (purchaseBrandName) {
      data["purchaseBrandName"] = purchaseBrandName;
    }
    dataLayer = window["dataLayer"] || [];
    dataLayer.push(data);
  }
  ecommerceEvents(data: any) {
    dataLayer = window["dataLayer"] || [];
    dataLayer.push(data);
  }
  getBranchContentItem(item) {
    const { our_price,price, quantity, product_sku,sku, product_id,id, product_name,name, brand, brand_name, variant_name, category, discount } = item;
    return {
      "$price": parseFloat(our_price||price),
      "$quantity": parseInt(quantity),
      "$sku": product_sku||sku,
      "$product_id": product_id||id,
      "$product_name": product_name||name,
      "$product_brand": brand || brand_name,
      "$product_variant": variant_name,
      "category": category,
      "$discount": discount,
      "$condition": "NEW"
    }

  }

  set404MetaResponse(status: any) {
    this.setMetaPrerender(status);
  }

  setProductMarkup(markupJson) {
    let markup = {
      "@context": "http://schema.org",
      "@type": "Product",
      name: markupJson["name"],
      url: markupJson["url"],
    };
    if (markupJson["description"]) {
      let description = markupJson["description"];
      markup["description"] = description;
    }
    if (markupJson["image_url"]) {
      let image = markupJson["image_url"];
      markup["image"] = image;
    }
    if (markupJson["brand_name"]) {
      let brand = {
        "@type": "Thing",
        name: markupJson["brand_name"],
      };

      markup["brand"] = brand;
    }
    if (parseInt(markupJson["rating_count"]) > 0 && parseInt(markupJson["rating_avg"]) > 0 && parseInt(markupJson["review_count"]) > 0) {
      let aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: markupJson["rating_avg"],
        reviewCount: markupJson["review_count"],
        ratingCount: markupJson["rating_count"],
        worstRating: "1",
        bestRating: "5",
      };
      markup["aggregateRating"] = aggregateRating;
    }
    if (markupJson["our_price"]) {
      let offer = {
        "@type": "Offer",
        availability: parseInt(markupJson["stock_status"]) == 1 ? "http://schema.org/InStock" : "http://schema.org/SoldOut",
        price: markupJson["our_price"],
        priceCurrency: "INR",
      };
      markup["offers"] = offer;
    }
    if (markupJson["sku"]) {
      markup["sku"] = markupJson["sku"];
    }

    const script = this.document.createElement("script");
    script.setAttribute("type", "application/ld+json");
    script.textContent = JSON.stringify(markup, null, 4);
    this.document.head.appendChild(script);
  }
}
